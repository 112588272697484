import { H } from "@jfrk/react-heading-levels";
import {
  URLSearchParamsProvider,
  LazyMinisearchSearchBackendProvider,
  SearchContextDebug,
  SearchForm,
  SearchPagination,
} from "@whitespace/gatsby-plugin-search";
import clsx from "clsx";
import * as React from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";

import ArchiveResults from "../components/ArchiveResults";

import * as searchStyles from "./Search.module.css";

const contact = () => {
  const { t } = useTranslation();

  return (
    <div className={clsx(searchStyles.component)}>
      <div className={clsx(searchStyles.wrapper)}>
        <H className={clsx(searchStyles.title)}>{t("contactLabel")}</H>
        <URLSearchParamsProvider
          forcedParams={{ contentType: "contact" }}
          schema={yup.object({
            query: yup.string().default(""),
            // organisationCities: yup
            // .array()
            // .of(yup.string())
            // .ensure()
            // .when("contentType", (contentType, schema) =>
            //   ["contact"].includes(contentType)
            //     ? schema
            //     : schema.strip(),
            // ),
            // organisationRoles: yup
            // .array()
            // .of(yup.string())
            // .ensure()
            // .when("contentType", (contentType, schema) =>
            //   ["contact"].includes(contentType)
            //     ? schema
            //     : schema.strip(),
            // ),
          })}
        >
          <LazyMinisearchSearchBackendProvider
            preload={true}
            settings={{
              attributesForFaceting: ["contentType"],
            }}
          >
            <SearchForm className={searchStyles.form} showHitsTotal={false} />
            {process.env.NODE_ENV !== "production" && (
              <details>
                <summary>Debug</summary>
                <SearchContextDebug />
              </details>
            )}
            <ArchiveResults postType="contact" />
            <SearchPagination />
          </LazyMinisearchSearchBackendProvider>
        </URLSearchParamsProvider>
      </div>
    </div>
  );
};

export default contact;
